@import "_variables.scss";

.badge-status-container {
    width: 288px;
    height: auto;
    border-radius: $border-radius-large;
    box-shadow: $box-shadow-default;

    &.reduced-height {
        height: 300px;
    }

    .badge-status-completion {
        background-color: $color-sidebar-hover-bg;
        padding: $padding-large;
        border-radius: $border-radius-large $border-radius-large 0 0;

        .completion-number {
            font-size: $font-size-med;
            font-weight: $font-weight-semi-bold;
            line-height: $line-height-large;
            display: flex;
            gap: var(--chakra-space-4);

            .completion-text {
                font-size: $font-size-reg;
                font-weight: $font-weight-medium;
                line-height: $line-height-medium;
                color: $color-font-card;
            }
        }

        .completion-divider {
            opacity: 1;
            border: 1px solid $color-link-hover-color;
            margin: $padding-large 0;
        }
    }

    .action-button-holder {
        margin-top: var(--chakra-space-4);
        display: flex;
        flex-direction: column;
        gap: var(--chakra-space-12-8);

        .action-button {
            font-size: $font-size-reg;
            line-height: $line-height-medium;

            .action-button-icon {
                height: 24px;
                width: 24px;
                margin-right: var(--chakra-space-4);
            }
        }
    }


}

.badge-detail-holder {
    width: 288px;
    .badge-detail-intro {
    font-size: $font-size-med;
    font-weight: $font-weight-600;
    line-height: $line-height-medium;
    padding-bottom: var(--chakra-space-8);
    }
    .badge-detail-main {
    border: 1px solid $color-font-card;
    border-radius: calc(var(--chakra-space-6) + var(--chakra-space-px));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--chakra-space-6) var(--chakra-space-8);
    gap: var(--chakra-space-8);

    .badge-detail-sub {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--chakra-space-8);
        opacity: 1;
        &.not-complete{
            opacity: 0.6;
        }
        .badge-detail-sub-title {
            font-size: $font-size-med;
            font-weight: $font-weight-600;
            line-height: $line-height-medium;
            color: $color-default-font-color;
            }
        }
        .badge-detail-status-holder {
            width: 100%;
            .tag-default{
                width: auto;
              }
        }
    }
}